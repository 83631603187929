.breadcrumb {
  font-size: 0.875rem;
  letter-spacing: 1px;
  line-height: 100%;
  margin-bottom: 2rem;

  &--item-label:last-child {
    cursor: default;
  }

  &--item-label:not(:last-child) {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &--item-icon {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
