@import 'components/colors';
$fontFamily: 'Rubik', sans-serif;
$fontFamilyMono: 'Anonymous Pro', monospace;

// Libraries
@import 'libs/odyssey';
@import 'libs/react-select';
@import 'libs/react-datepicker';
@import 'bulma/bulma.sass';
@import 'libs/bulma-steps.sass';
@import 'react-tabs/style/react-tabs.scss';

$standardPadding: 1.6rem;
$sidebarIconWidth: 1.5rem;
$sidebarWidth: 4rem;

$sidebarWidthExpanded: 270px;
$topbarHeight: 3.25rem;
$floatingWindowBackground: rgba(255, 255, 255, 0.95);
$floatingWindowWidth: 550px;
$floatingShadow: 0 0 27px rgba(27, 27, 27, 0.27);
$announcementsHeight: 2rem;

// Components
@import 'components/sidebar';
@import 'components/topbar';
@import 'components/table';
@import 'components/breadcrumb';
@import 'components/tooltip';
@import 'components/logo';
@import 'components/commons';
@import 'components/pages';
@import 'components/login';
@import 'components/app';
@import 'components/button';
@import 'components/contract-request';
@import 'components/iconButton';
@import 'components/details';
@import 'components/flowPayment';

//  Global settings
#app {
  position: relative;
  display: block;
  min-height: 100%;
  height: 100%;
  min-width: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

#app-content {
  position: relative;
  display: block;
  height: 100%;
  min-height: 100%;
  z-index: 0;
  width: calc(100% - #{(($standardPadding * 2 + $sidebarIconWidth))});
  padding-top: $topbarHeight + $standardPadding;
  padding-left: $standardPadding;
  padding-right: $standardPadding;
  padding-bottom: $standardPadding * 2 + $announcementsHeight;
  margin-left: ($standardPadding * 2 + $sidebarIconWidth);
  @include border-box();
}
.column p {
  margin-bottom: 1em;
}

.avatar {
  width: 20px;
  margin-right: 0.5em;
  border-radius: 50%;
}

.file-content-help {
  font-size: 0.7em;
  font-weight: normal;
}

#nprogress .bar {
  z-index: 9999999999;
}
