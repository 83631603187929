$sidebar-background: $dark-black;
$sidebar-background-hover: $dark-grey;
$sidebar-color: $light-grey;
$itemsVerticalPadding-small: 0.88rem;
$itemsVerticalPadding-large: 1.3rem;

#app-sidebar {
  position: fixed;
  display: block;
  width: ($standardPadding * 2 + $sidebarIconWidth);
  padding-top: $topbarHeight;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $sidebar-background;
  z-index: 1000;

  @include border-box();
  @include transition(width 40ms ease-in-out 0ms);

  &:hover {
    width: $sidebarWidthExpanded;
    @include transition(width 140ms ease-in-out 800ms);
  }

  .menu-items {
    list-style-type: none;
    min-height: 100%;
    margin-bottom: -($itemsVerticalPadding-small * 2 + 1.9rem);

    li {
      display: block;
      padding: $itemsVerticalPadding-small $standardPadding;
      color: $sidebar-color;
      white-space: nowrap;
      cursor: pointer;
      @include unselectable();
      @include transition((background-color 70ms, color 70ms) ease-in-out);

      &:hover {
        background-color: $sidebar-background-hover;
        color: $color-primary;
        i {
          color: inherit;
        }
      }

      i {
        display: inline-block;
        width: $sidebarIconWidth;
        text-align: center;
        position: relative;
        font-size: 1.2rem;
        margin-right: $standardPadding;
        color: $sidebar-color;
      }

      &.active {
        border-right: 4px solid $color-primary;
        i {
          color: $color-primary;
        }
      }
    }
    hr.break {
      display: block;
      width: 100%;
      height: 1px !important;
      font-size: 1rem;
      background-color: $sidebar-background-hover;
      margin-top: 0;
      margin-bottom: 0;
    }

    &:after {
      content: '';
      display: block;
      height: ($itemsVerticalPadding-small * 2 + 1.9rem);
    }
  }

  .content-info-container {
    height: ($itemsVerticalPadding-small * 2 + 1.9rem);
    margin-bottom: -($itemsVerticalPadding-small * 2 + 1.9rem);

    li {
      display: block;
      padding: $itemsVerticalPadding-small $standardPadding;
      color: $sidebar-color;
      white-space: nowrap;
      cursor: pointer;
      @include unselectable();
      @include transition((background-color 70ms, color 70ms) ease-in-out);

      &:hover {
        background-color: $sidebar-background-hover;
        color: $color-primary;
        i {
          color: inherit;
        }
      }

      i {
        display: inline-block;
        width: $sidebarIconWidth;
        text-align: center;
        position: relative;
        font-size: 1.2rem;
        margin-right: $standardPadding;
        color: $sidebar-color;
      }

      &.active {
        border-right: 4px solid $color-primary;
        i {
          color: $color-primary;
        }
      }
    }
  }

  @media only screen and (min-height: 730px) {
    .menu-items {
      margin-bottom: -($itemsVerticalPadding-large * 2 + 1.9rem);
      li {
        padding: $itemsVerticalPadding-large $standardPadding;
      }
      &:after {
        height: ($itemsVerticalPadding-large * 2 + 1.9rem);
      }
    }
    .content-info-container {
      height: ($itemsVerticalPadding-large * 2 + 1.9rem);
      margin-bottom: -($itemsVerticalPadding-large * 2 + 1.9rem);
      li {
        padding: $itemsVerticalPadding-large $standardPadding;
      }
    }
  }
}
