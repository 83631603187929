.icon-button {
  background: transparent;
  cursor: pointer;
  height: 18px;
  position: relative;
  width: 18px;
  i {
    font-size: 18px;
    color: $button-primary;
    margin: 0;
  }
  &:not(.iconOnly):hover {
    i {
      color: $button-primary-hover;
    }
  }
  &:hover {
    .tooltip {
      @include opacity(1);
    }
  }
  &:not(.iconOnly):focus {
    outline: solid blue 2px;
  }
  &:not(:last-child) {
    margin-right: 14px;
  }
  &.iconOnly {
    cursor: default;
  }
  &.left {
    margin-left: 0.5rem;
  }
  &.right {
    margin-right: 0.5rem;
  }
  &.search {
    position: absolute;
    right: 20px;
    top: 38px;
  }
  &.margin-left {
    margin-left: 2rem;
  }
  &.size {
    i {
      font-size: 21px;
    }
  }
  &.cancel {
    & > i {
      color: $medium-grey;
      &:not(.iconOnly):hover {
        color: $button-secondary !important;
      }
    }
    &:not(.iconOnly):focus {
      outline: none;
    }
  }
  &.modified-value {
    i {
      color: #ffae42 !important;
      font-size: 21px;
    }
    &:not(.iconOnly):focus {
      outline: none;
    }
  }
  &.outline {
    i {
      font-size: 21px;
    }
    &:not(.iconOnly):focus {
      outline: none;
    }
  }
  &:disabled {
    pointer-events: none;
    & > i {
      color: #9c9c9c;
    }
  }
}

.tooltip {
  display: inline-block;
  position: absolute;
  top: 2.25rem;
  left: 50%;
  padding: 0.3rem 0.5rem;
  border: 1px solid $button-primary;
  background-color: $white;
  font-size: 0.6125rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  z-index: 100;
  transform: translateX(-50%);
  pointer-events: none;
  color: $dark-grey-alt;
  @include border-radius(4px);
  @include opacity(0);
  @include transition(opacity 200ms ease-in-out 20ms);
  &:before {
    position: absolute;
    display: inline-block;
    width: 10px;
    height: 10px;
    top: -5px;
    left: 50%;
    content: '';
    transform: translateX(-50%) rotate(45deg);
    z-index: 99;
    pointer-events: none;
    background-color: $button-primary;
  }
  &:hover {
    z-index: 10000;
  }
}
