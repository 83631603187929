.file-previewer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 75vh;
  position: fixed;
  right: 0;
  text-align: center;
}
.file-previewer img {
  display: block;
  flex: none;
  height: auto;
  margin: auto;
  max-width: none;
  width: 100%;
}

.document-container {
  display: flex;
  margin: auto;
  overflow: scroll;
  width: 90%;
  padding-top: 1rem; 
}
