.newFlowPayment {
  max-width: 950px;
  margin: 0 auto;
}
.newFlow {
  max-width: 950px;
  margin: 0 auto;
  &--field {
    margin-bottom: 2rem;
    position: relative;
    width: 100%;
    & > input {
      border: 1px solid $light-grey-hover;
      border-radius: 3px;
      padding: 0.5rem;
      width: 30%;
      margin-top: 0.5rem;
      &::placeholder {
        color: $medium-grey;
      }
    }
    & > label {
      font-weight: 700;
    } 
  }
  &--width {
    display: flex;
    justify-content: space-between;
    & > input {
      width: 45%;
    }
  }
  &--select {
    margin-bottom: 1rem;
    & > div:nth-child(1) {
      margin-right: 2rem;
    }
  }
}
.disabled {
  & > input {
    pointer-events: none;
    border-color: whitesmoke;
    background-color: whitesmoke;
  }
}

.marginTop {
  margin-top: 2rem;
}
.margin {
  & p:nth-child(1) {
    margin-right: 2rem;
  }
}
.input-full-width {
  & > input {
    width: 100%;
  }
}
.input-medium {
  width: 50%;
  & >  input {
    width: 100%;
  }
}

.massage {
  color: $color-scheme-error;
  font-size: 0.7rem;
  position: absolute;
  bottom: -20px;
  left: 0;
  &.required-modal {
    position: initial;
  }
}

.selectField {
  width: 40%;
  margin-top: 1.5rem;
}
