$topbar-base: $dark-black;
$topbar-background: $dark-grey;
$topbar-background-hover: $dark-grey-hover;
$topbar-color: $light-grey;
$topbar-height: 3.5rem;
$resuelveLogo-height: 2rem;

#app-topbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: $topbar-height;
  position: fixed;
  background-color: $topbar-base;
  z-index: 2000;

  .topbar-section {
    display: flex;
    flex-wrap: nowrap;
    .item {
      display: flex;
      position: relative;
      cursor: pointer;
      @include unselectable();
      &:hover {
        .submenu {
          height: auto;
        }
      }

      #resuelve-logo {
        display: inline-block;
        height: $topbar-height;
        @include border-box();
        &:hover {
          background-color: $topbar-background-hover;
        }
        & > img {
          width: 200px;
        }
      }

      button {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        font-weight: 500;
        padding: 1.2rem;
        text-transform: uppercase;
        line-height: 0;
        letter-spacing: 1px;
        background-color: transparent;
        color: $topbar-color;
        cursor: pointer;
        width: 100%;
        height: $topbar-height;
        white-space: nowrap;
        &:hover {
          background-color: $topbar-background-hover;
        }
        i {
          font-size: 1.2rem;
        }
        &.active {
          color: $color-primary;
        }
      }

      button.primary {
        background-color: $color-primary;
        color: $color-primary-contrast;
        &:hover {
          background-color: $color-primary-hover;
        }
      }

      .submenu {
        position: absolute;
        top: 100%;
        height: 0;
        z-index: 1001;
        background-color: $topbar-background;
        overflow: hidden;
        width: 100%;
        button {
          text-align: right;
        }
        &.left {
          left: 0;
        }
        &.right {
          right: 0;
        }
      }
    }
  }
}
