$color-primary: #007da4;
$color-primary-contrast: #ffffff;
$color-primary-hover: #1fcaff;
$color-secondary: #003764;
$color-secondary-hover: #003764;
$green-check-logo: #8dba38;
$blue-resuelve-logo: #007ca2;


$light-grey: #f7f7f7;
$light-grey-alt: #f0f0f0;
$light-grey-hover: #e0e0e0;

$medium-grey: #999999;
$white: #fff;

$button-primary: #00d1b2;
$button-primary-hover: #00c4a7;
$button-secondary: #ff3860;
$button-secondary-hover: #ff3a29;

$dark-grey: #474747;
$dark-grey-alt: #272727;
$dark-grey-hover: #373737;
$apple-grey: #efeff4;

$dark-black: #171717;
$select-color: rgba(255, 221, 87, 0.45);

/*
 * Enconta proposed redesign palette
 */

$color-scheme-main-1: #94c941;
$color-scheme-main-2: #12a84c;
$color-scheme-main-3: #00aeab;
$color-scheme-main-4: #0d81a1;
$color-scheme-main-5: #de208c;
$color-scheme-main-6: #111820;
$color-scheme-alt-1: #83d0d0;
$color-scheme-alt-2: #1b9db2;
$color-scheme-alt-3: #e0569f;
$color-scheme-alt-4: #565656;
$color-scheme-alt-5: #919395;
$color-scheme-alt-6: #cccccc;
$color-scheme-alt-7: #f1f1f1;
$color-scheme-error: #ec1c24;
