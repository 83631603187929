$buttonIcon-margin: 0.5rem;

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.flex-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vertical-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.align-self-start {
  align-self: flex-start;
}

.relative {
  position: relative;
}
.position-button {
  top: 7px !important;
}
.position-loading {
  top: -18px !important;
}

.background-select {
  background: $select-color;
}

.suggestion {
  border: 1px solid $apple-grey;
  padding: 0.5rem;
  border-radius: 3px;
}

.searching {
  position: absolute;
  right: 20px;
  top: 14px;
}

.register-results {
  padding: 1.5rem 0;
  color: $medium-grey;
  font-size: 14px;
}

.warning {
  color: $color-scheme-main-5;
  text-decoration: underline;
}

.width {
  width: 95% !important;
}

.link {
  color: $color-primary;
  cursor: pointer;
  &:hover {
    color: $color-primary-hover;
  }
}

.field-space {
  & div {
    margin-right: 1rem;
  }
  &.field-width {
    width: 60%;
  }
}

.filter-accounting {
  width: 35% !important;
}

.text-center {
  text-align: center;
}

.full-width {
  width: 100% !important;
}
.medium-width {
  width: 45% !important;
}

.input-top {
  margin-top: 0.5rem;
}
.no-margin {
  margin: 0 !important;
}
.no-padding {
  padding: 0 !important;
}

.field:not(:last-child) {
  margin-bottom: 0;
}
.block {
  display: block;
}

.text-left {
  margin-left: 1.3rem;
}
.text-right {
  margin-right: 1.3rem;
}
.capitalize {
  text-transform: capitalize;
}

.bottom {
  margin-bottom: 1.5rem;
}
.renapo-title {
  color: #363636;
  display: block;
  font-size: 1rem;
  font-weight: 700;
}
.top {
  margin-top: 1.5rem;
}
.mdi {
  font-size: large;
  > i {
    color: $medium-grey !important;
  }
}
.flag {
  margin-right: 0.5rem;
}
.optional-text {
  margin-left: 5px;
  font-size: 12px;
  color: $medium-grey;
  font-weight: 400;
}
button {
  > i {
    margin-left: 0;
    margin-right: $buttonIcon-margin;
  }
}
div [role='combobox'] {
  width: 100%;
}

.card-footer-item {
  > i {
    margin-left: 0;
    margin-right: $buttonIcon-margin;
  }
}
.is-black {
  color: $dark-black;
}
.is-bold {
  font-weight: 500 !important;
}

.subtitle {
  text-transform: uppercase;
  font-weight: bold;
  font-size: small;
}
.to-uppercase {
  text-transform: uppercase;
}

//Bulma steps
.steps .steps-marker {
  height: 1.4rem;
  width: 1.4rem;
}
.steps-segment:after {
  height: 0.3em !important;
  left: 1.3rem !important;
  right: -0.24rem !important;
  top: calc(1rem - (0.4em)) !important;
}
.steps-segment.is-active:after {
  background-color: #ff3860;
}

.hidden {
  display: none !important;
}
.copy {
  position: absolute !important;
  right: 0;
  top: 10px;
  padding: 0 !important;
  & > span > i {
    color: $medium-grey;
  }
  &.coreButton:not(:last-child) {
    margin: 0;
  }
}
.overlay {
  background-color: #6f6f6f;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0.4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.waiting-for-tab {
  width: 100%;
  display: inline-block;
  background-color: #007da4;
  height: 16px;
  animation-name: waitingForDataAnimation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes waitingForDataAnimation {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.8;
  }
}
.is-loading {
  border: 0.5rem solid $white;
  border-radius: 50%;
  border-top: 0.5rem solid #3498db;
  width: 3rem;
  height: 3rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.main-tab {
  width: 70%;
  margin: 0 auto;
  & li {
    width: 200px;
    text-align: center;
    font-weight: 600;
  }
}

.react-tabs__tab--selected {
  border: none;
  background: transparent;
  border-bottom: 4px solid $color-primary;
  color: $color-primary;
}
.pdf-viewer {
  background: url('https://mi.montevideo.gub.uy/app/miSTM/promociones/resources/img/loading.gif;jsessionid=ULqb96XFRyFEZhEJfnf6c5DZUHvKeBfmg6i5QtRd.mistm101prodv')
    no-repeat center;
}
.invalid-key {
  i {
    color: $color-scheme-error !important;
  }
}

.total-request {
  border-left: 4px solid #ffae42 !important;
}
.search-receptor {
  top: 7px !important;
}
.loading-receptor {
  top: 0 !important;
}
.button-upload {
  color: $color-primary !important;
  border: 1px solid $color-primary !important;
  &.disabled,
  &[disabled] {
    opacity: 0.8;
    cursor: not-allowed;
    color: $white !important;
    border: none !important;
  }
}
