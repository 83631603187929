.flowDetails {
  background: $white;
  width: 400px;
  position: absolute;
  right: -400px;
  top: 0;
  height: 100vh;
  padding: 4rem 1rem 1rem 1rem;
  transition: 0.5s;
  box-shadow: -3px 1px 8px 0px rgba(217,217,217,1);
  overflow-y: auto;
  position: fixed;
  z-index: 5;
  &--subtitle {
    margin-bottom: 2rem;
    & > p {
      font-size: 1rem;
      font-weight: 4500;
    }
  }
  &--show {
    right: 0;
    transition: 0.5s;
  }
  &--active {
    display: block;
    margin-bottom: 0.75rem;
    font-size: 1rem;
    font-weight: 500;
  }
  &--deactive {
    display: none;
  }
  &--select {
    background: $select-color;
  }
  &--center {
    text-align: center;
    border: 1px solid $medium-grey;
    font-weight: 500;
    margin-bottom: 1rem;
  }
}
.text-bottom > p {
  margin-bottom: 2rem;
}

