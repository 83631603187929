$background-image-page: '/img/bg-sessions.png';

#page-login {
  position: relative;
  background-image: url($background-image-page);
  background-position: center;
  background-size: cover;
  min-height: 100%;
  height: 100%;

  /* Fixes position bug */
  &:before {
    position: relative;
    display: block;
    content: '';
    width: 1px;
    height: 1px;
    pointer-events: none;
  }

  .login-content {
    position: relative;
    width: 100%;
    max-width: $floatingWindowWidth;
    margin: 0 auto;
    padding: 2rem $standardPadding;
    background-color: $floatingWindowBackground;
    @include box-shadow($floatingShadow);
    top: 50%;
    transform: translateY(-50%);

    #enconta-logo {
      position: relative;
      display: block;
      width: 80%;
      max-width: 250px;
      margin: 1rem auto 2rem;
      color: red;
    }
  }

  .login-top-2rem {
    margin-top: 2rem;
  }
}
