.google-button {
  display: inline-block;
  white-space: nowrap;
  margin: 0 auto;
  width: 230px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4285f4;
  border-radius: 4px;
  font-weight: 700;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  color: white !important;
  &:hover {
    outline: none;
    transform: scale(1.1);
  }
}

.google-icon {
  background: url(/img/g.png) transparent 5px 50% no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}

.google-button-text {
  display: inline-block;
  vertical-align: middle;
  padding-left: 42px;
  padding-right: 42px;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
}

.google-styles {
  background: none;
  color: '#fff';
}
