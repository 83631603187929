.input[disabled],
.textarea[disabled] {
  cursor: auto;
}

.files {
  max-height: 75vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.phases-container {
  margin-top: 23px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.button[disabled] {
  pointer-events: none;
}
