.coreButton {
  align-items: center;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 1rem;
  height: 2.285em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  position: relative;
  vertical-align: top;
  user-select: none;
  background-color: white;
  color: #363636;
  cursor: pointer;
  justify-content: center;
  padding-left: 0.75em;
  padding-right: 0.75em;
  text-align: center;
  white-space: nowrap;
  &:hover {
    .tooltip {
      @include opacity(1);
      margin-top: 1rem;
    }
  }
  &.loading {
    pointer-events: none;
    &.is-fullwidth {
      > span {
        > i {
          margin-right: 0;
        }
      }
    }
  }
  &.primary {
    background: $button-primary;
    color: $white;
    &:hover {
      background: $button-primary-hover;
    }
  }
  &.secondary {
    background: $button-secondary;
    color: $white;
    &:hover {
      background: $button-secondary-hover;
    }
  }
  &.create {
    color: $color-primary;
    border: 1px solid $color-primary;
  }
  &.upload {
    background: $color-primary;
    color: $white;
  }
  
  &.clear {
    background: $medium-grey;
    color: $white;
  }
  &.link {
    color: $color-primary-hover;
    cursor: pointer;
    background: transparent;
    text-transform: uppercase;
    &:hover {
      color: $color-primary-hover;
    }
  }
  &.filter-country {
    box-shadow: 0px 0px 4px 1px rgba(224,224,224,1);
    color: $medium-grey;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 1rem;
    & > span img {
      margin: 0;
    }
  }
  &.filter-selected {
    background: rgba(0, 125, 164, 0.2);
    border: 1.5px solid rgba(0, 125, 164, 0.5);
    color: $color-primary;
  }
  &.is-country {
    border: 1px solid $color-scheme-main-2;
    color: $color-scheme-main-2;
    transform: scale(1.05);
  }
  &.disabled,
  &[disabled] {
    background: $light-grey-hover;
    color: $white;
    cursor: not-allowed;
    &:hover {
      background: $light-grey-hover;
    }
  }
  &:not(:last-child) {
    margin-right: 1rem;
  }
  &.is-fullwidth {
    width: 100%;
  }
  &.right {
    margin-right: 0.6rem;
  }
  &.left {
    margin-left: 0.6rem;
  }
  &.top {
    margin-top: 1.3rem; 
  }
  &.bold {
    font-weight: 600;
  }
}
